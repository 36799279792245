const INITIAL_STATE = {
  listAllPortfolio: [],
  detailsPortfolio: {},
  portfolioTrader: [],
}

const apiPortfolio = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DETAILSPORTFOLIO': {
      return { ...state, detailsPortfolio: action.payload }
    }
    case 'ALLPORTFOLIO': {
      return { ...state, listAllPortfolio: action.payload }
    }
    case 'PORTFOLTRADER': {
      return { ...state, portfolioTrader: action.payload }
    }
    default:
      return state
  }
}

export default apiPortfolio
