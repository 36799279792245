import axios from 'axios'
import React, { useState } from 'react'
import { UserActions } from '../store/actions'
import {
  resultListUsers,
  resultUserAuth,
  resultToken,
  resultListIdentity,
  resultListDataEdit,
  resultGettingUsers,
  resultGetOneUser,
  resultGetIdentityTrader,
  resultKeyAuthorisation,
  // resultTokenTemporary,
  // resultOpenEmailDialog,
  // resultOpenDeleteDialog,
} from '../store/reducers'
import { useDispatch, useSelector } from 'react-redux'
import authService from '../services/AuthServices'
import { callApi } from 'src/services/callApi'
import { getUserInfo } from '../services/ApiService/user'

const useUser = () => {
  const [temporaryToken, setTemporaryToken] = useState('')

  const addIdentity = (identity) => {
    dispatch(UserActions.addIdentity(identity))
  }

  const getKeyAutho = (dataKeyAuthorisation) => {
    dispatch(UserActions.getKeyAutho(dataKeyAuthorisation))
  }

  // const setTempToken = (dataTokenTemporary) => {
  //   dispatch(UserActions.setTempToken(dataTokenTemporary))
  // }

  // const setOpenEditDialog = (dataOpenEditDialog) => {
  //   dispatch(UserActions.setOpenEditDialog(dataOpenEditDialog))
  // }

  // const setOpenAddDialog = (dataOpenAddDialog) => {
  //   dispatch(UserActions.setOpenAddDialog(dataOpenAddDialog))
  // }

  const deleteIdent = (data) => {
    dispatch(UserActions.deleteIdent(data))
  }

  const dataEdit = (dataListDataEdit) => {
    dispatch(UserActions.dataEdit(dataListDataEdit))
  }

  const getAllTraderIdentity = (dataGetIdentityTrader) => {
    dispatch(UserActions.getAllTraderIdentity(dataGetIdentityTrader))
  }

  const getOneUser = (dataGetOneUser) => {
    dispatch(UserActions.getOneUser(dataGetOneUser))
  }

  const gettingUsers = (dataGettingUser) => {
    dispatch(UserActions.gettingUsers(dataGettingUser))
  }

  const listIdentity = (dataListIdentity) => {
    dispatch(UserActions.listIdentity(dataListIdentity))
  }

  const getToken = (dataToken) => {
    dispatch(UserActions.getToken(dataToken))
  }

  const getUserAuth = (dataUserAuth) => {
    dispatch(UserActions.getUserAuth(dataUserAuth))
  }

  const getAllUsers = (dataListUsers) => {
    dispatch(UserActions.getAllUsers(dataListUsers))
  }

  const getMeInfo = async () => {
    try {
      // const me = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/getUserInfo`)
      const me = await getUserInfo()
      if (me.data) {
        return me.data
      }
      // const me = await callApi('/users/getUserInfo', 'GET', null)
      // if (me.data) {
      //   return me.data
      // }
    } catch (error) {
      console.log(error)
    }
  }

  const getAuthorisationKey = async (data) => {
    try {
      const resp = await callApi('/users/getAuthorisationKey', 'POST', data)

      const formData = new FormData()
      formData.append('username', resp?.data?.idUser)
      formData.append('password', resp?.data?.apiKey)
      // formData.append('username', '650afb3abc070d002b88e30d')
      // formData.append('password', 'nRLPxoxLUbGfWYqngC00hZNhsTh5eR')

      const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
      const tokenAuth = await axios.post(`${apiQuanttoolsUrl}/token`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for FormData
        },
      })

      getKeyAutho(tokenAuth?.data?.access_token)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const changePwdUser = async (data) => {
    try {
      const resp = await callApi('/users/changePassword', 'POST', data)
      return resp
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const createUser = async (data) => {
    try {
      // const resp = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/user`, data)
      const resp = await callApi('/users/user', 'POST', data)
      return resp
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const updateUsers = async (data) => {
    try {
      // const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/updateUsers`, data)
      const res = await callApi('/users/updateUsers', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const simpleUpdateUsers = async (data) => {
    try {
      // const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/updateUsers`, data)
      const res = await callApi('/users/SimpleUpdateUsers', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const verifMdpExist = async (id) => {
    try {
      // const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/updateUsers`, data)
      const res = await callApi('/users/verifExistMdp', 'POST', id)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const verifCompteActiveTokenAndMdp = async (token) => {
    try {
      // const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/updateUsers`, data)
      const res = await callApi('/users/verifCompteActiveMdp', 'POST', token)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const updatedPassword = async (data) => {
    try {
      // const respons = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/updatePassword`,
      //   data,
      // )
      const respons = await callApi('/users/updatePassword', 'POST', data)
      return respons
    } catch (err) {
      console.log(err)
    }
  }

  const forgotUpdatedPwd = async (data) => {
    try {
      // const respons = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/updateForgotPwd`,
      //   data,
      // )
      const respons = await callApi('/users/updateForgotPwd', 'POST', data)
      return respons
    } catch (err) {
      console.log(err)
    }
  }

  const verifTokenExist = async (token) => {
    try {
      const respons = await callApi('/users/verifTokenExist', 'POST', token)
      return respons
    } catch (err) {
      console.log(err)
    }
  }

  const forgotPassword = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/forgotPassword`,
      //   data,
      // )
      const res = await callApi('/users/forgotPassword', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const getAllUser = async () => {
    try {
      // const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/getAllUser`)
      const data = await callApi('/users/getAllUser', 'GET', null)
      await getAllUsers(data.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const getAllIdentity = async () => {
    try {
      // const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/getAllIdentity`)
      const data = await callApi('/users/getAllIdentity', 'GET', null)
      listIdentity(data.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const filterIdentity = async (filter, idClient) => {
    try {
      // const result = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/filterIdentity`,
      //   { filter },
      // )
      const result = await callApi('/users/filterIdentity', 'POST', { filter, idClient })

      await listIdentity(result.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const FilterClientIdentity = async (filter) => {
    try {
      const data = await callApi('/users/filterClientIdentity', 'POST', filter)

      listIdentity(data.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const login = async (username, password) => {
    try {
      const resp = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/login`, {
        username,
        password,
      })

      // sessionStorage.setItem('tokenNew', resp?.data?.tokenQuant?.access_token)
      setTemporaryToken(resp?.data)

      return resp
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const removeUser = async (data) => {
    try {
      // const resp = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/deleteIdentity`,
      //   data,
      // )
      const resp = await callApi('/users/deleteIdentity', 'POST', data)
      return resp
      // dispatch(UserActions.deleteIdent(id))
    } catch (err) {
      console.log(err)
    }
  }

  const createTradeAndDeletedRisk = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/createTradeAndDeletedRisk`,
      //   data,
      // )
      const res = await callApi('/users/createTradeAndDeletedRisk', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const createRiskAndDeletedTrade = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/createRiskAndDeletedTrade`,
      //   data,
      // )
      const res = await callApi('/users/createRiskAndDeletedTrade', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const updateUsersProfilTraders = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/updateUsersProfilTraders`,
      //   data,
      // )
      const res = await callApi('/users/updateUsersProfilTraders', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const updateUsersProfilRisk = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/updateUsersProfilRisk`,
      //   data,
      // )
      const res = await callApi('/users/updateUsersProfilRisk', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const getIdentityTrader = async (data) => {
    try {
      // const result = await axios.get(
      //   `${process.env.REACT_APP_API_ENDPOINT}/users/getIdentityTrader`,
      // )
      const result = await callApi('/users/getIdentityTrader', 'POST', { data })
      getAllTraderIdentity(result.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const sendRecuperCode = async (data) => {
    try {
      await callApi('/auth/send-otp', 'POST', data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const verifCodeLogin2 = async (data) => {
    try {
      const res = await callApi('/auth/validate-otp', 'POST', data)
      console.log('VALIDE ', res)
      if (res?.data === 'OTP is valid!') {
        authService.setAccessToken(temporaryToken?.token)
      }
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const getAllIdentityArchived = async () => {
    try {
      // const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/getAllIdentity`)
      const data = await callApi('/users/getAllUserArchived', 'GET', null)
      return data
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const dispatch = useDispatch()

  const dataToken = useSelector(resultToken)
  const dataUserAuth = useSelector(resultUserAuth)
  const dataListUsers = useSelector(resultListUsers)
  const dataListIdentity = useSelector(resultListIdentity)
  const dataListDataEdit = useSelector(resultListDataEdit)
  const dataGettingUser = useSelector(resultGettingUsers)
  const dataGetOneUser = useSelector(resultGetOneUser)
  const dataGetIdentityTrader = useSelector(resultGetIdentityTrader)
  const dataKeyAuthorisation = useSelector(resultKeyAuthorisation)
  // const dataTokenTemporary = useSelector(resultTokenTemporary)
  // const dataOpenEmailDialog = useSelector(resultOpenEmailDialog)
  // const dataOpenDeleteDialog = useSelector(resultOpenDeleteDialog)

  return {
    ...dataToken,
    ...dataUserAuth,
    ...dataListUsers,
    ...dataListIdentity,
    ...dataListDataEdit,
    ...dataGettingUser,
    ...dataGetOneUser,
    ...dataGetIdentityTrader,
    ...dataKeyAuthorisation,
    // ...dataTokenTemporary,
    // ...dataOpenEmailDialog,
    // ...dataOpenDeleteDialog,
    login,
    getAllUsers,
    getUserAuth,
    dataEdit,
    updateUsers,
    simpleUpdateUsers,
    updatedPassword,
    forgotPassword,
    FilterClientIdentity,
    forgotUpdatedPwd,
    createUser,
    filterIdentity,
    getAllUser,
    getMeInfo,
    getToken,
    listIdentity,
    getAllIdentity,
    removeUser,
    // getUserInfo,
    gettingUsers,
    updateUsersProfilTraders,
    updateUsersProfilRisk,
    createTradeAndDeletedRisk,
    createRiskAndDeletedTrade,
    getOneUser,
    getAllTraderIdentity,
    getIdentityTrader,
    deleteIdent,
    verifMdpExist,
    // setOpenDetailUserDialog,
    // setOpenEmailDialog,
    // setOpenDeleteDialog,
    addIdentity,
    verifTokenExist,
    verifCompteActiveTokenAndMdp,
    changePwdUser,
    sendRecuperCode,
    verifCodeLogin2,
    getAllIdentityArchived,
    getAuthorisationKey,
    getKeyAutho,
  }
}

export default useUser
