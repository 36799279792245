import axios from 'axios'
import { callApi } from '../callApi'

export const fetchDataOverview = async (sessionToken, getDataOverviewInApi) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const axiosInstance = await axios.create({
    baseURL: `${apiQuanttoolsUrl}`,
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json', // Vous pouvez ajuster le type de contenu selon vos besoins
    },
  })

  axiosInstance.get('/dashboard/overview_trades/').then((response) => {
    getDataOverviewInApi(response?.data)
  })
}

export const fetchBinaryPdfData = async (dataGenerate) => {
  const res = await callApi('/newProductTypes/getBinaryPdfData', 'POST', {
    tradeId: dataGenerate?.trade_id,
  })
  return res
}

export const downloadTransactionNotice = async (dataGenerate, sessionToken) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const result = await axios({
    url: `${apiQuanttoolsUrl}/transaction_notice/download/`,
    method: 'POST',
    data: dataGenerate,
    // data: {
    //   trade_id: '64d4d607e97f54e3099370eb',
    // },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
    },
    responseType: 'blob', // Spécifiez que la réponse est un blob (fichier)
  })
  return result
}

export const genererTransactionNotice = async (dataGenerate, sessionToken) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  await axios({
    url: `${apiQuanttoolsUrl}/transaction_notice/generate/`,
    method: 'POST',
    data: dataGenerate,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
    },
  })
}

export const genererExcel = async (dataGenerate, sessionToken) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const response = await axios.post(
    `${apiQuanttoolsUrl}/dashboard/overview_trades_excel/`,
    dataGenerate,
    // {
    //   portfolios: ['650afe5012c2c6002b034078'],
    //   dashboard_report: true,
    //   trades_details: true,
    // },
    {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        // Authorization:
        //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NTBhZmIzYWJjMDcwZDAwMmI4OGUzMGQiLCJleHAiOjE2OTU2MjM3Nzd9.CQ3h7k3qxWM9kRj4E29EOBK1mZZGvFg2ipsybKlkXnM',
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer', // Important for binary data like files
    },
  )
  return response
}
