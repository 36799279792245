import axios from 'axios'

const ACCESS_TOKEN = 'ACCESS_TOKEN'
const ROLE = 'ROLE'
const USERNAME = 'USERNAME'
const FIRSTNAME = 'FIRSTNAME'

const authService = {
  clearToken: () => localStorage.clear(),

  setAccessToken: (token) => localStorage.setItem(ACCESS_TOKEN, token),

  setUserInfo: (role) => localStorage.setItem(ROLE, role),

  setUserName: (name) => localStorage.setItem(USERNAME, name),

  setUserFirstName: (firstname) => localStorage.setItem(FIRSTNAME, firstname),

  getAccessToken: () => localStorage.getItem(ACCESS_TOKEN),
}

export default authService
