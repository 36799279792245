const INITIAL_STATE = {
  listClient: [],
}

const apiClient = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ALLCLIENT': {
      return { ...state, listClient: action.payload }
    }
    default:
      return state
  }
}

export default apiClient
