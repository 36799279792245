const INITIAL_STATE = {
  sidebarShow: true,
  listCall: [],
  listPut: [],
  listCallStrike: [],
  listButterk1: [],
  prixBtc: {},
  prixEth: {},
  priceUsd: 0,
  priceBtc: 0,
  volumeDispo: 0,
  marketCondition: 'Bull Market',
  maxGain: 0,
  strikePayOff: [],
  lossPayOff: [],
  lossPayOffMax: [],
  typeUnderlying: 'BTC',
  valCouvExpo: 0,
  strikeK1Put: 0,
  typeProduct: 'strategie1',
  priceStructuring: {},
  dataQuantTools: [],
  datafastPrices: {},
  dataMarginFinancing: true,
  dataSize: 0,
  dataPriceShort: 0,
  newProductType: 'call_spread',
  dateMaturity: '',
  strikek1G: 0,
  strikek2G: 0,
  strikek3G: 0,
  resultPrexecution: {},
  resultExecutionProduit: {},
  notifFastPrice: { message: '', notif: false },
  positionGlobal: 'L',
  earlyTerminationPreExec: {},
}

const apiInstrument = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case 'ALLUSERS': {
    //   return { ...state, listUsers: action.payload }
    // }
    case 'TYPEPRODUCT': {
      return { ...state, typeProduct: action.payload }
    }
    case 'VALCOUVEXPO': {
      return { ...state, valCouvExpo: action.payload }
    }
    case 'STRIKEK1PUT': {
      return { ...state, strikeK1Put: action.payload }
    }
    case 'TYPEUNDERLYING': {
      return { ...state, typeUnderlying: action.payload }
    }
    case 'LOSSPAYOFFMAX': {
      return { ...state, lossPayOffMax: action.payload }
    }
    case 'LOSSPAYOFF': {
      return { ...state, lossPayOff: action.payload }
    }
    case 'STRIKEPAYOFF': {
      return { ...state, strikePayOff: action.payload }
    }
    case 'MAXGAIN': {
      return { ...state, maxGain: action.payload }
    }
    case 'MARKETCONDITION': {
      return { ...state, marketCondition: action.payload }
    }
    case 'VOLUMEDISPO': {
      return { ...state, volumeDispo: action.payload }
    }
    case 'PRICINGBTC': {
      return { ...state, priceBtc: action.payload }
    }
    case 'PRICEUSD': {
      return { ...state, priceUsd: action.payload }
    }
    case 'set': {
      return { ...state, sidebarShow: action.payload }
    }
    case 'LISTCALL': {
      return { ...state, listCall: action.payload }
    }
    case 'PRIXBTC': {
      return { ...state, prixBtc: action.payload }
    }
    case 'PRIXETH': {
      return { ...state, prixEth: action.payload }
    }
    case 'LISTPUT': {
      return { ...state, listPut: action.payload }
    }
    case 'LISTCALLSTRIKE': {
      return { ...state, listCallStrike: action.payload }
    }
    case 'LISTEBUTTERK1': {
      return { ...state, listButterk1: action.payload }
    }
    case 'STRUCTURINGPRICE': {
      return { ...state, priceStructuring: action.payload }
    }
    case 'DATAQUANTTOOLS': {
      return { ...state, dataQuantTools: action.payload }
    }
    case 'DATAFASTPRICES': {
      return { ...state, datafastPrices: action.payload }
    }
    case 'VALMARGINFINANCING': {
      return { ...state, dataMarginFinancing: action.payload }
    }
    case 'VALSIZE': {
      return { ...state, dataSize: action.payload }
    }
    case 'PRICESHORT': {
      return { ...state, dataPriceShort: action.payload }
    }
    case 'PRODUCTTYPE': {
      return { ...state, newProductType: action.payload }
    }
    case 'DATEMATURITY': {
      return { ...state, dateMaturity: action.payload }
    }
    case 'STRIKEK1': {
      return { ...state, strikek1G: action.payload }
    }
    case 'STRIKEK2': {
      return { ...state, strikek2G: action.payload }
    }
    case 'STRIKEK3': {
      return { ...state, strikek3G: action.payload }
    }
    case 'RESULTPREXEC': {
      return { ...state, resultPrexecution: action.payload }
    }
    case 'RESULTEXECPRODUIT': {
      return { ...state, resultExecutionProduit: action.payload }
    }
    case 'NOTIFFASTPRICE': {
      return { ...state, notifFastPrice: action.payload }
    }
    case 'POSITIONVALUE': {
      return { ...state, positionGlobal: action.payload }
    }
    case 'PREXECUTETERMINATION': {
      return { ...state, earlyTerminationPreExec: action.payload }
    }
    default:
      return state
  }
}

export default apiInstrument
