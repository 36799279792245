export const setDetailsPortfolio = (data) => (dispatch) => {
  dispatch({
    type: 'DETAILSPORTFOLIO',
    payload: data,
  })
}

export const listPortfolio = (data) => (dispatch) => {
  dispatch({
    type: 'ALLPORTFOLIO',
    payload: data,
  })
}

export const getListPortfolTrader = (data) => (dispatch) => {
  dispatch({
    type: 'PORTFOLTRADER',
    payload: data,
  })
}
