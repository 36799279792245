export const listIdentity = (data) => (dispatch) => {
  dispatch({
    type: 'ALLIDENTITY',
    payload: data,
  })
}

export const getToken = (data) => (dispatch) => {
  dispatch({
    type: 'TOKEN',
    payload: data,
  })
}

export const getUserAuth = (data) => (dispatch) => {
  dispatch({
    type: 'USERAUTH',
    payload: data,
  })
}

export const getAllUsers = (data) => (dispatch) => {
  dispatch({
    type: 'ALLUSERS',
    payload: data,
  })
}

export const dataEdit = (data) => (dispatch) => {
  dispatch({
    type: 'EDITUSER',
    payload: data,
  })
}

export const deleteIdent = (data) => (dispatch) => {
  dispatch({
    type: 'DELETEIDENTITY',
    payload: data,
  })
}

export const gettingUsers = (data) => (dispatch) => {
  dispatch({
    type: 'GETUSERS',
    payload: data,
  })
}

export const getOneUser = (data) => (dispatch) => {
  dispatch({
    type: 'GETONEUSER',
    payload: data,
  })
}

export const getAllTraderIdentity = (data) => (dispatch) => {
  dispatch({
    type: 'GETTRADERS',
    payload: data,
  })
}

export const getKeyAutho = (data) => (dispatch) => {
  dispatch({
    type: 'KEYAUTHORISATION',
    payload: data,
  })
}

// export const setOpenDetailUserDialog = (data) => (dispatch) => {
//   dispatch({
//     type: 'OPENDETAILUSERDIALOG',
//     payload: data,
//   })
// }

// export const setOpenEmailDialog = (data) => (dispatch) => {
//   dispatch({
//     type: 'OPENEMAILDIALOG',
//     payload: data,
//   })
// }

// export const setTempToken = (data) => (dispatch) => {
//   dispatch({
//     type: 'TOKENTEMPORAIRE',
//     payload: data,
//   })
// }

export const addIdentity = (data) => (dispatch) => {
  dispatch({
    type: 'ADDIDENTITY',
    payload: data,
  })
}
