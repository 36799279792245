import axios from 'axios'
import authService from '../AuthServices'

export const getUserInfo = async () => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/getUserInfo`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const changePwdUser = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/changePassword`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const createUser = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/user`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const updateUsers = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/updateUsers`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const verifMdpExist = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/verifExistMdp`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const verifCompteActiveTokenAndMdp = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/verifCompteActiveMdp`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const updatedPassword = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/updatePassword`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const forgotUpdatedPwd = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/updateForgotPwd`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const verifTokenExist = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/verifTokenExist`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const forgotPassword = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/forgotPassword`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getAllIdentityArchived = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/getAllUserArchived`,
      method: '`GET`',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const verifCodeLogin2 = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/auth/validate-otp`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const sendRecuperCode = async (data) => {
  try {
    await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/auth/send-otp`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateUsersProfilRisk = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/updateUsersProfilRisk`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateUsersProfilTraders = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/updateUsersProfilTraders`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createRiskAndDeletedTrade = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/createRiskAndDeletedTrade`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const removeUser = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/deleteIdentity`,
      method: '`POST`',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

const setUser = (infoUser) => {
  if (!infoUser) {
    // getUserAuth({})
    localStorage.clear()
    return
  }

  const { token } = infoUser
  authService.setAccessToken(token)
  // getUserAuth(user)
}

export const login = async (username, password) => {
  try {
    const resp = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/users/deleteIdentity`,
      method: '`POST`',
      data: {
        username,
        password,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    setUser(resp.data)

    return resp
  } catch (error) {
    console.log(error)
    return null
  }
}
