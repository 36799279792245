import { combineReducers } from 'redux'
import instrumentReducer from './instrument'
import userReducer from './user'
import clientReducer from './client'
import portfolioReducer from './portfolio'
import overviewReducer from './overview'

const createRootReducer = () =>
  combineReducers({
    portfolioOverview: overviewReducer,
    selectDash: overviewReducer,
    detailsPortfolio: portfolioReducer,
    portfolioTrader: portfolioReducer,
    dataEditUser: userReducer,
    keyAuthorisation: userReducer,
    listAllPortfolio: portfolioReducer,
    allIdentity: userReducer,
    listClient: clientReducer,
    token: userReducer,
    userAuth: userReducer,
    listUsers: userReducer,
    gettingUser: userReducer,
    typeProduct: instrumentReducer,
    valCouvExpo: instrumentReducer,
    strikeK1Put: instrumentReducer,
    typeUnderlying: instrumentReducer,
    lossPayOffMax: instrumentReducer,
    lossPayOff: instrumentReducer,
    strikePayOff: instrumentReducer,
    marketCondition: instrumentReducer,
    volumeDispo: instrumentReducer,
    priceBtc: instrumentReducer,
    priceUsd: instrumentReducer,
    sidebarShow: instrumentReducer,
    listCall: instrumentReducer,
    listPut: instrumentReducer,
    listCallStrike: instrumentReducer,
    listButterk1: instrumentReducer,
    prixBtc: instrumentReducer,
    prixEth: instrumentReducer,
    maxGain: instrumentReducer,
    oneUserInfo: userReducer,
    identityTrader: userReducer,
    openAddDialog: userReducer,
    openEditDialog: userReducer,
    openDetailUserDialog: userReducer,
    openEmailDialog: userReducer,
    // tokenTemporary: userReducer,
    openDeleteDialog: userReducer,
    priceStructuring: instrumentReducer,
    dataQuantTools: instrumentReducer,
    datafastPrices: instrumentReducer,
    dataMarginFinancing: instrumentReducer,
    dataSize: instrumentReducer,
    dataPriceShort: instrumentReducer,
    newProductType: instrumentReducer,
    dateMaturity: instrumentReducer,
    strikek1G: instrumentReducer,
    strikek2G: instrumentReducer,
    strikek3G: instrumentReducer,
    resultPrexecution: instrumentReducer,
    resultExecutionProduit: instrumentReducer,
    positionGlobal: instrumentReducer,
    notifFastPrice: instrumentReducer,
    earlyTerminationPreExec: instrumentReducer,
    dataOverview: overviewReducer,
    dataCriptPdf: overviewReducer,
  })

const rootReducer = createRootReducer()

export default rootReducer

export const resultPortfolioOverview = (state) => state.portfolioOverview

export const resultDataOverview = (state) => state.dataOverview

export const resultDetailsPortfolio = (state) => state.detailsPortfolio

export const resultListDataEdit = (state) => state.dataEditUser

export const resultListPortfolio = (state) => state.listAllPortfolio

export const resultListIdentity = (state) => state.allIdentity

export const resultListClient = (state) => state.listClient

export const resultToken = (state) => state.token

export const resultUserAuth = (state) => state.userAuth

export const resultListUsers = (state) => state.listUsers

export const resultGettingUsers = (state) => state.gettingUser

export const resultTypeProduct = (state) => state.typeProduct

export const resultValCouvExpo = (state) => state.valCouvExpo

export const resultstrikeK1Put = (state) => state.strikeK1Put

export const resultTypeUnderlying = (state) => state.typeUnderlying

export const resultLossPayOffMax = (state) => state.lossPayOffMax

export const resultLossPayOff = (state) => state.lossPayOff

export const resultStrikePayOff = (state) => state.strikePayOff

export const resultMaxGain = (state) => state.maxGain

export const resultMarketCondition = (state) => state.marketCondition

export const resultVolumeDispo = (state) => state.volumeDispo

export const resultPriceBtc = (state) => state.priceBtc

export const resultPriceUsd = (state) => state.priceUsd

export const resultSideBarShow = (state) => state.sidebarShow

export const resultListCall = (state) => state.listCall

export const resultListPut = (state) => state.listPut

export const resultListCallStrike = (state) => state.listCallStrike

export const resultPrixBtc = (state) => state.prixBtc

export const resultPrixEth = (state) => state.prixEth

export const resultListButterk1 = (state) => state.listButterk1

export const resultGetOneUser = (state) => state.oneUserInfo

export const resultGetIdentityTrader = (state) => state.identityTrader

export const resultportfolioSelectedPandL = (state) => state.portfolioSelectedPandL

export const resultPriceStructuring = (state) => state.priceStructuring

export const resultQuantTools = (state) => state.dataQuantTools

export const resultFastPrices = (state) => state.datafastPrices

export const resultMarginFinancing = (state) => state.dataMarginFinancing

export const resultSize = (state) => state.dataSize

export const resultPriceShort = (state) => state.dataPriceShort

export const resultSelectDash = (state) => state.selectDash

export const resultKeyAuthorisation = (state) => state.keyAuthorisation

export const resultNewProductType = (state) => state.newProductType

export const resultDateMaturity = (state) => state.dateMaturity

export const resultStrikeK1 = (state) => state.strikek1G

export const resultStrikeK2 = (state) => state.strikek2G

export const resultStrikeK3 = (state) => state.strikek3G

export const resultPreExecution = (state) => state.resultPrexecution

export const resultPortfolioTrader = (state) => state.portfolioTrader

export const resultExecutionProduit = (state) => state.resultExecutionProduit

export const resultDataCriptPDF = (state) => state.dataCriptPdf

export const resultNotifFastPrice = (state) => state.notifFastPrice

export const resultPositionGlobal = (state) => state.positionGlobal

export const resultPreExecTermination = (state) => state.earlyTerminationPreExec

// export const resultTokenTemporary = (state) => state.tokenTemporary
