import axios from 'axios'
import React from 'react'
import { Navigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ token, children }) => {
  if (token) {
    // const sessionToken = sessionStorage.getItem('tokenNew')
    // if (!sessionToken) {

    //   sessionStorage.setItem('tokenNew', resp?.data?.tokenQuant?.access_token)
    // }

    const tokenAccess = localStorage.getItem('ACCESS_TOKEN')
    axios.defaults.headers.common.Authorization = `Bearer ${tokenAccess}`
    return children
  }
  return <Navigate to="/login" replace />
}

export default ProtectedRoute
