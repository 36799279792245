import { InstrumentActions } from '../store/actions'
import {
  resultListUsers,
  resultTypeProduct,
  resultLossPayOffMax,
  resultLossPayOff,
  resultValCouvExpo,
  resultstrikeK1Put,
  resultTypeUnderlying,
  resultStrikePayOff,
  resultMaxGain,
  resultMarketCondition,
  resultVolumeDispo,
  resultPriceBtc,
  resultSideBarShow,
  resultListCall,
  resultListPut,
  resultListButterk1,
  resultListCallStrike,
  resultPriceUsd,
  resultPrixBtc,
  resultPrixEth,
  resultPriceStructuring,
  resultQuantTools,
  resultFastPrices,
  resultMarginFinancing,
  resultSize,
  resultPriceShort,
  resultNewProductType,
  resultDateMaturity,
  resultStrikeK1,
  resultStrikeK2,
  resultStrikeK3,
  resultPreExecution,
  resultExecutionProduit,
  resultNotifFastPrice,
  resultPositionGlobal,
  resultPreExecTermination,
} from '../store/reducers'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash'
import { useState } from 'react'
import { callApi } from 'src/services/callApi'
import {
  fetchAuthorisationKey,
  listInstrument,
  orderBooks,
  fetchStrikePut,
  fetchPriceStructuring,
  fetchDataQuantTools,
  fastPriceQuantTools,
  preExecCall,
  getTokenQuantTools,
  preExecEarlyTerm,
  execTermination,
  productExecution,
} from '../services/ApiService/instrument'
import { useOverview, useUser } from '../hooks'

const useInstrument = () => {
  const [dataMaturity, setDataMaturity] = useState([])

  const { fetchToken } = useOverview()

  const { userAuth } = useUser()

  const getTypeProduct = (dataTypeProduct) => {
    dispatch(InstrumentActions.getTypeProduct(dataTypeProduct))
  }

  const setValCouvExpo = (dataValCouvExpo) => {
    dispatch(InstrumentActions.setValCouvExpo(dataValCouvExpo))
  }

  const setStrikeK1Put = (dataStrikeK1Put) => {
    dispatch(InstrumentActions.setStrikeK1Put(dataStrikeK1Put))
  }

  const setTypeUnderlying = (dataTypeUnderlying) => {
    dispatch(InstrumentActions.setTypeUnderlying(dataTypeUnderlying))
  }

  const setLossPayOffMax = (dataLossPayOffMax) => {
    dispatch(InstrumentActions.setLossPayOffMax(dataLossPayOffMax))
  }

  const setLossPayOff = (dataLossPayOff) => {
    dispatch(InstrumentActions.setLossPayOff(dataLossPayOff))
  }

  const setStrikePayOff = (dataStrikePayOff) => {
    dispatch(InstrumentActions.setStrikePayOff(dataStrikePayOff))
  }

  const setMaxGain = (dataMaxGain) => {
    dispatch(InstrumentActions.setMaxGain(dataMaxGain))
  }

  const setMarketCondition = (dataMarketCondition) => {
    dispatch(InstrumentActions.setMarketCondition(dataMarketCondition))
  }

  const setVolumeDispo = (dataVolumeDispo) => {
    dispatch(InstrumentActions.setVolumeDispo(dataVolumeDispo))
  }

  const setPricingBtc = (dataPriceBtc) => {
    dispatch(InstrumentActions.setPricingBtc(dataPriceBtc))
  }

  const setPriceUsd = (dataPriceUsd) => {
    dispatch(InstrumentActions.setPriceUsd(dataPriceUsd))
  }

  const setShowSideBar = (dataSideBarShow) => {
    dispatch(InstrumentActions.setShowSideBar(dataSideBarShow))
  }

  const setListCall = (dataCall) => {
    dispatch(InstrumentActions.setListCall(dataCall))
  }

  const setListPut = (dataPut) => {
    dispatch(InstrumentActions.setListPut(dataPut))
  }

  const setListCallStrike = (dataListeCallStrike) => {
    dispatch(InstrumentActions.setListCallStrike(dataListeCallStrike))
  }

  const setPriceBtc = (dataPrixBtc) => {
    dispatch(InstrumentActions.setPriceBtc(dataPrixBtc))
  }

  const setPriceEth = (dataPrixEth) => {
    dispatch(InstrumentActions.setPriceEth(dataPrixEth))
  }

  const setListButterK1 = (dataListButterK1) => {
    dispatch(InstrumentActions.setListButterK1(dataListButterK1))
  }

  const setStructuringPrice = (dataPriceStructuring) => {
    dispatch(InstrumentActions.setStructuringPrice(dataPriceStructuring))
  }

  const setDataQuantTools = (dataQuantTools) => {
    dispatch(InstrumentActions.setDataQuantTools(dataQuantTools))
  }

  const setResultFastPrices = (dataFastPrices) => {
    dispatch(InstrumentActions.setResultFastPrices(dataFastPrices))
  }

  const getMarginFinancing = (dataMarginFinancing) => {
    dispatch(InstrumentActions.getMarginFinancing(dataMarginFinancing))
  }

  const getSize = (dataSize) => {
    dispatch(InstrumentActions.getSize(dataSize))
  }

  const getPriceShort = (dataPriceShort) => {
    dispatch(InstrumentActions.getPriceShort(dataPriceShort))
  }

  const setNewProductType = (dataNewProductType) => {
    dispatch(InstrumentActions.setNewProductType(dataNewProductType))
  }

  const setDateMaturity = (dataDateMaturity) => {
    dispatch(InstrumentActions.setDateMaturity(dataDateMaturity))
  }

  const setStrikek1 = (dataStrikeK1) => {
    dispatch(InstrumentActions.setStrikek1(dataStrikeK1))
  }

  const setStrikek2 = (dataStrikeK2) => {
    dispatch(InstrumentActions.setStrikek2(dataStrikeK2))
  }

  const setStrikek3 = (dataStrikeK3) => {
    dispatch(InstrumentActions.setStrikek3(dataStrikeK3))
  }

  const getResultPreExec = (dataResultPrexecution) => {
    dispatch(InstrumentActions.getResultPreExec(dataResultPrexecution))
  }

  const getResultExecProduit = (dataResultExecutionProduit) => {
    dispatch(InstrumentActions.getResultExecProduit(dataResultExecutionProduit))
  }

  const NotificationFastPrice = (dataResultNotifFastPrice) => {
    dispatch(InstrumentActions.NotificationFastPrice(dataResultNotifFastPrice))
  }

  const getPosition = (dataResultPositionGlobal) => {
    dispatch(InstrumentActions.getPosition(dataResultPositionGlobal))
  }

  const getEarlyTerminationPreExecute = (dataPreExecTermination) => {
    dispatch(InstrumentActions.getEarlyTerminationPreExecute(dataPreExecTermination))
  }

  const getDataBackEnd = () => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/listInstrument`).then((res) => {
      console.log('DATA BACK ', res.data)
    })

    // return {
    //   ...data,
    //   setListRess,
    //   InstrumentAxios,
    // }
  }

  const GetListeInstruments = async (Instru) => {
    // instrument_name

    await listInstrument(Instru, setDataMaturity, setListCall)
    // axios
    //   .get(
    //     `https://www.deribit.com/api/v2/public/get_instruments?currency=${Instru}&expired=false&kind=option`,
    //   )
    //   .then((res) => {
    //     const length = res.data.result.length
    //     for (let i = 0; i < length; i++) {
    //       InstrumentName.push(res.data.result[i])
    //     }

    //     setDataMaturity(InstrumentName)

    //     const instrumentNames = InstrumentName.map((item) => item.instrument_name)
    //     const datesMaturite = instrumentNames.map((name) => name.split('-')[1])

    //     const MaturityFiltred = _.sortedUniq(datesMaturite)

    //     setListCall(MaturityFiltred)
    //   })
  }

  const GetStrike = async (maturityDate) => {
    const finalyValue = []
    const requests = []

    for (let i = 0; i < dataMaturity.length; i++) {
      const name = dataMaturity[i].instrument_name
      const parts = name.split('-')
      const dateMatur = parts[1].toString()

      if (name.endsWith('-C') && dateMatur === maturityDate) {
        // const request = axios.get(
        //   `https://www.deribit.com/api/v2/public/get_order_book?instrument_name=${name}`,
        // )
        const request = await orderBooks(name)
        requests.push(request)
      }
    }

    const responses = await Promise.all(requests)

    for (let j = 0; j < responses.length; j++) {
      const response = responses[j]
      const objet = { ...response.data.result }

      if (objet.bids.length && objet.asks.length) {
        const matury = objet.instrument_name
        const parts = matury.split('-')
        const price = parseInt(parts[2])
        objet.strikeVal = price
        finalyValue.push(objet)
      }
    }

    return finalyValue

    // setListCallStrike(finalyValue)
  }

  const GetStrikePut = async (maturityDate) => {
    const finalyValue = []
    const requests = []

    for (let i = 0; i < dataMaturity.length; i++) {
      const name = dataMaturity[i].instrument_name
      const parts = name.split('-')
      const dateMatur = parts[1].toString()

      if (name.endsWith('-P') && dateMatur === maturityDate) {
        // const request = axios.get(
        //   `https://www.deribit.com/api/v2/public/get_order_book?instrument_name=${name}`,
        // )
        const request = await fetchStrikePut(name)
        requests.push(request)
      }
    }

    const responses = await Promise.all(requests)

    for (let j = 0; j < responses.length; j++) {
      const response = responses[j]
      const objet = { ...response.data.result }

      if (objet.bids.length && objet.asks.length) {
        const matury = objet.instrument_name
        const parts = matury.split('-')
        const price = parseInt(parts[2])
        objet.strikeVal = price
        finalyValue.push(objet)
      }
    }

    return finalyValue

    // setListPut(finalyValue)
  }

  const GetStrikeButterfly = async (maturityDate) => {
    const finalyValueC = []
    const finalyValueP = []
    const requestsC = []
    const requestsP = []

    for (let i = 0; i < dataMaturity.length; i++) {
      const name = dataMaturity[i].instrument_name
      const parts = name.split('-')
      const dateMatur = parts[1].toString()

      if (name.endsWith('-C') && dateMatur === maturityDate) {
        // const request = axios.get(
        //   `https://www.deribit.com/api/v2/public/get_order_book?instrument_name=${name}`,
        // )
        const request = await fetchStrikePut(name)
        requestsC.push(request)
      }
    }

    for (let i = 0; i < dataMaturity.length; i++) {
      const name = dataMaturity[i].instrument_name
      const parts = name.split('-')
      const dateMatur = parts[1].toString()

      if (name.endsWith('-P') && dateMatur === maturityDate) {
        // const request = axios.get(
        //   `https://www.deribit.com/api/v2/public/get_order_book?instrument_name=${name}`,
        // )
        const request = await fetchStrikePut(name)
        requestsP.push(request)
      }
    }

    const responsesC = await Promise.all(requestsP)
    const responsesP = await Promise.all(requestsC)

    for (let j = 0; j < responsesC.length; j++) {
      const response = responsesC[j]
      const objet = { ...response.data.result }

      if (objet.bids.length && objet.asks.length) {
        const matury = objet.instrument_name
        const parts = matury.split('-')
        const price = parseInt(parts[2])
        objet.strikeVal = price
        finalyValueC.push(objet)
      }
    }

    for (let j = 0; j < responsesP.length; j++) {
      const response = responsesP[j]
      const objet = { ...response.data.result }

      if (objet.bids.length && objet.asks.length) {
        const matury = objet.instrument_name
        const parts = matury.split('-')
        const price = parseInt(parts[2])
        objet.strikeVal = price
        finalyValueP.push(objet)
      }
    }

    const AllResult = []

    for (let z = 0; z < finalyValueC.length; z++) {
      for (let p = 0; p < finalyValueP.length; p++) {
        if (finalyValueC[z].strikeVal === finalyValueP[p].strikeVal) {
          AllResult.push(finalyValueC[z], finalyValueP[p])
        }
      }
    }

    const newArray = AllResult.reduce(function (acc, curr) {
      // trouver Index dans le tableau où le strikeVal correspondait
      const findIfStrikevalExist = acc.findIndex(function (item) {
        return item.strikeVal === curr.strikeVal
      })
      // si dans le nouveau tableau aucun objet de ce type
      // n'existe où strikeVal correspond, créez un nouvel objet
      if (findIfStrikevalExist === -1) {
        const obj = {
          strikeVal: curr.strikeVal,
          value: [curr],
        }
        acc.push(obj)
      } else {
        // si la catégorie de nom correspond, alors poussez la valeur
        acc[findIfStrikevalExist].value.push(curr)
      }
      return acc
    }, [])

    return newArray

    // setListButterK1(newArray)
  }

  const GetPriceStructuring = async () => {
    // axios
    //   .request({
    //     timeout: 8000,
    //     method: 'GET',
    //     url: `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/underlying_prices/`,
    //   })
    //   .then((response) => {
    //     setStructuringPrice(response?.data)

    //     NotificationFastPrice({ message: '', notif: false })
    //   })
    //   .catch((error) => {
    //     console.log('ERREUR', error)
    //     NotificationFastPrice({ message: error?.message, notif: true })
    //   })

    await fetchPriceStructuring(setStructuringPrice, NotificationFastPrice)
  }

  const GetDataQuantTools = async () => {
    try {
      // const datas = await axios.get(
      //   `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/products/?with_constraints=true`,
      // )
      const datas = await fetchDataQuantTools(userAuth, fetchToken)
      setDataQuantTools(datas?.data)
    } catch (err) {
      console.log(err)
    }
  }

  const PostFastpricesQuantTools = async (data) => {
    try {
      // const datas = await axios.post(
      //   `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/fast-prices/`,
      //   data,
      // )
      const datas = await fastPriceQuantTools(data)
      await setResultFastPrices(datas.data)
      NotificationFastPrice({ message: '', notif: false })
    } catch (error) {
      console.log('ERORR ', error)
      NotificationFastPrice({ message: error?.message, notif: true })
    }
  }

  const callPreExecution = async (keyAuth, data) => {
    try {
      // const result = await axios({
      //   url: `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/pre-execution/`,
      //   method: 'POST',
      //   data: data,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${keyAuth}`,
      //   },
      // })
      const result = await preExecCall(data, keyAuth)

      getResultPreExec(result.data)

      NotificationFastPrice({ message: '', notif: false })
      return result
    } catch (error) {
      console.log('ERROR ', error)
      NotificationFastPrice({ message: error?.message, notif: true })
    }
  }

  const PreExecutionEarlyTermination = async (keyAuth, data) => {
    try {
      // const resp = await callApi('/users/getAuthorisationKey', 'POST', keyAuth)
      const resp = await fetchAuthorisationKey(keyAuth)

      const formData = new FormData()
      formData.append('username', resp?.data?.idUser)
      formData.append('password', resp?.data?.apiKey)
      // formData.append('username', '650afb3abc070d002b88e30d')
      // formData.append('password', 'nRLPxoxLUbGfWYqngC00hZNhsTh5eR')

      // const tokenAuth = await axios.post(
      //   'https://staging.phalanx-capital.eu:8444/token',
      //   formData,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data', // Important for FormData
      //     },
      //   },
      // )
      const tokenAuth = await getTokenQuantTools(formData)

      // const result = await axios({
      //   url: `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/early-termination-preexecution/`,
      //   method: 'POST',
      //   data: data,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${tokenAuth?.data?.access_token}`,
      //   },
      // })
      const result = await preExecEarlyTerm(data, tokenAuth)

      getEarlyTerminationPreExecute(result.data)

      NotificationFastPrice({ message: '', notif: false })
      return result
    } catch (error) {
      console.log('ERROR ', error)
      NotificationFastPrice({ message: error?.message, notif: true })
    }
  }

  const executionTermination = async (keyAuth, data) => {
    try {
      const resp = await callApi('/users/getAuthorisationKey', 'POST', keyAuth)

      const formData = new FormData()
      formData.append('username', resp?.data?.idUser)
      formData.append('password', resp?.data?.apiKey)
      // formData.append('username', '650afb3abc070d002b88e30d')
      // formData.append('password', 'nRLPxoxLUbGfWYqngC00hZNhsTh5eR')

      // const tokenAuth = await axios.post(
      //   'https://staging.phalanx-capital.eu:8444/token',
      //   formData,
      //   {
      //     headers: {
      //       'Content-Type': 'multipart/form-data', // Important for FormData
      //     },
      //   },
      // )

      const tokenAuth = await getTokenQuantTools(formData)

      // const result = await axios({
      //   url: `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/early-termination-execution/`,
      //   method: 'POST',
      //   data: data,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${tokenAuth?.data?.access_token}`,
      //   },
      // })
      const result = await execTermination(data, tokenAuth)

      NotificationFastPrice({ message: '', notif: false })
      return result
    } catch (error) {
      console.log('ERROR ', error)
      NotificationFastPrice({ message: error?.message, notif: true })
    }
  }

  const executionProduct = async (keyAuth, data) => {
    try {
      // const result = await axios({
      //   url: `https://staging.phalanx-capital.eu:8444/pricing/structuredproduct/execution/`,
      //   method: 'POST',
      //   data: data,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${keyAuth}`,
      //   },
      // })
      const result = await productExecution(data, keyAuth)
      NotificationFastPrice({ message: '', notif: false })
      getResultExecProduit(result.data)
      return result.data
    } catch (error) {
      NotificationFastPrice({ message: error?.message, notif: true })
    }
  }

  const dispatch = useDispatch()

  const dataListUsers = useSelector(resultListUsers)
  const dataTypeProduct = useSelector(resultTypeProduct)
  const dataValCouvExpo = useSelector(resultValCouvExpo)
  const dataStrikeK1Put = useSelector(resultstrikeK1Put)
  const dataTypeUnderlying = useSelector(resultTypeUnderlying)
  const dataLossPayOffMax = useSelector(resultLossPayOffMax)
  const dataLossPayOff = useSelector(resultLossPayOff)
  const dataStrikePayOff = useSelector(resultStrikePayOff)
  const dataMaxGain = useSelector(resultMaxGain)
  const dataMarketCondition = useSelector(resultMarketCondition)
  const dataPriceBtc = useSelector(resultVolumeDispo)
  const dataVolumeDispo = useSelector(resultPriceBtc)
  const dataPriceUsd = useSelector(resultPriceUsd)
  const dataSideBarShow = useSelector(resultSideBarShow)
  const dataCall = useSelector(resultListCall)
  const dataPut = useSelector(resultListPut)
  const dataListeCallStrike = useSelector(resultListCallStrike)
  const dataPrixBtc = useSelector(resultPrixBtc)
  const dataPrixEth = useSelector(resultPrixEth)
  const dataListButterK1 = useSelector(resultListButterk1)
  const dataPriceStructuring = useSelector(resultPriceStructuring)
  const dataQuantTools = useSelector(resultQuantTools)
  const dataFastPrices = useSelector(resultFastPrices)
  const dataMarginFinancing = useSelector(resultMarginFinancing)
  const dataSize = useSelector(resultSize)
  const dataPriceShort = useSelector(resultPriceShort)
  const dataNewProductType = useSelector(resultNewProductType)
  const dataDateMaturity = useSelector(resultDateMaturity)
  const dataStrikeK1 = useSelector(resultStrikeK1)
  const dataStrikeK2 = useSelector(resultStrikeK2)
  const dataStrikeK3 = useSelector(resultStrikeK3)
  const dataResultPrexecution = useSelector(resultPreExecution)
  const dataResultExecutionProduit = useSelector(resultExecutionProduit)
  const dataResultNotifFastPrice = useSelector(resultNotifFastPrice)
  const dataResultPositionGlobal = useSelector(resultPositionGlobal)
  const dataPreExecTermination = useSelector(resultPreExecTermination)

  return {
    ...dataListUsers,
    ...dataTypeProduct,
    ...dataValCouvExpo,
    ...dataStrikeK1Put,
    ...dataTypeUnderlying,
    ...dataLossPayOffMax,
    ...dataLossPayOff,
    ...dataStrikePayOff,
    ...dataMaxGain,
    ...dataMarketCondition,
    ...dataVolumeDispo,
    ...dataPriceBtc,
    ...dataPriceUsd,
    ...dataSideBarShow,
    ...dataCall,
    ...dataPut,
    ...dataListeCallStrike,
    ...dataPrixBtc,
    ...dataPrixEth,
    ...dataListButterK1,
    ...dataPriceStructuring,
    ...dataQuantTools,
    ...dataFastPrices,
    ...dataMarginFinancing,
    ...dataSize,
    ...dataPriceShort,
    ...dataNewProductType,
    ...dataDateMaturity,
    ...dataStrikeK1,
    ...dataStrikeK2,
    ...dataStrikeK3,
    ...dataResultPrexecution,
    ...dataResultExecutionProduit,
    ...dataResultNotifFastPrice,
    ...dataResultPositionGlobal,
    ...dataPreExecTermination,
    getDataBackEnd,
    GetStrike,
    GetStrikePut,
    GetStrikeButterfly,
    setShowSideBar,
    setListCall,
    setPriceUsd,
    setPricingBtc,
    setListPut,
    setVolumeDispo,
    setMaxGain,
    setMarketCondition,
    setPriceBtc,
    setPriceEth,
    setListButterK1,
    GetListeInstruments,
    setStrikePayOff,
    setTypeUnderlying,
    setValCouvExpo,
    setStrikeK1Put,
    setLossPayOff,
    setLossPayOffMax,
    getTypeProduct,
    // setUnderlying,
    GetPriceStructuring,
    GetDataQuantTools,
    setDataQuantTools,
    PostFastpricesQuantTools,
    getMarginFinancing,
    getSize,
    getPriceShort,
    callPreExecution,
    setNewProductType,
    setDateMaturity,
    setStrikek1,
    setStrikek2,
    setStrikek3,
    executionProduct,
    getPosition,
    PreExecutionEarlyTermination,
    executionTermination,
  }
}

export default useInstrument
