import { ClientActions } from '../store/actions'
import { resultListClient } from '../store/reducers'
// import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
// import authService from '../services/AuthServices'
import { callApi } from 'src/services/callApi'
import {
  newClient,
  listAllClient,
  modifClient,
  filtreClient,
  deleteClient,
  fetchClientArchived,
} from '../services/ApiService/client'

const useClient = () => {
  const getAllClient = (dataListClient) => {
    dispatch(ClientActions.getAllClient(dataListClient))
  }

  const createClient = async (data) => {
    try {
      // const resp = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/client/newClient`, data)
      // const resp = await callApi('/client/newClient', 'POST', data)
      const resp = await newClient(data)
      return resp
    } catch (err) {
      console.log('Error ', err)
    }
  }

  // const createTest = async (data) => {
  //   try {
  //     const resp = await axios.post(
  //       `${process.env.REACT_APP_API_ENDPOINT}/newProductTypes/newProduct`,
  //     )
  //     return resp
  //   } catch (err) {
  //     console.log('Error ', err)
  //   }
  // }

  const getAllListClients = async () => {
    try {
      // const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/client/listAllClients`)
      // const data = await callApi('/client/listAllClients', 'GET', null)
      const data = await listAllClient()
      await getAllClient(data.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const editClient = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/client/updateClient`,
      //   data,
      // )
      // await callApi('/client/updateClient', 'POST', data)
      await modifClient(data)
    } catch (err) {
      console.log(err)
    }
  }

  const filterClient = async (filter) => {
    try {
      // const result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/client/filterClient`, {
      //   filter,
      // })
      const result = await callApi('/client/filterClient', 'POST', { filter })
      // const result = await filtreClient({ filter })

      await getAllClient(result.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const removeClient = async (data) => {
    try {
      // const resp = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/client/deleteClient`,
      //   data,
      // )
      // const resp = await callApi('/client/deleteClient', 'POST', data)
      const resp = await deleteClient(data)
      return resp
      // dispatch(UserActions.deleteIdent(id))
    } catch (err) {
      console.log(err)
    }
  }

  const getClientArchived = async () => {
    try {
      // const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/client/listAllClients`)
      // const data = await callApi('/client/listClientsArchived', 'GET', null)
      const data = await fetchClientArchived()
      return data
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const dispatch = useDispatch()

  const dataListClient = useSelector(resultListClient)

  return {
    ...dataListClient,
    createClient,
    getAllListClients,
    editClient,
    filterClient,
    removeClient,
    getClientArchived,
    // createTest,
  }
}

export default useClient
