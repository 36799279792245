import { PortfolioActions } from '../store/actions'
import {
  resultListPortfolio,
  resultDetailsPortfolio,
  resultPortfolioTrader,
} from '../store/reducers'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash'
import authService from '../services/AuthServices'
import { useState } from 'react'
import { callApi } from 'src/services/callApi'

const usePortfolio = () => {
  const setDetailsPortfolio = (dataDetailsPortfolio) => {
    dispatch(PortfolioActions.setDetailsPortfolio(dataDetailsPortfolio))
  }

  const listPortfolio = (dataListPortfolio) => {
    dispatch(PortfolioActions.listPortfolio(dataListPortfolio))
  }

  const getListPortfolTrader = (dataPortfolioTrader) => {
    dispatch(PortfolioActions.getListPortfolTrader(dataPortfolioTrader))
  }

  const createPortfolio = async (data) => {
    try {
      // const resp = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/newPortfolio`,
      //   data,
      // )
      const resp = await callApi('/portfolio/newPortfolio', 'POST', data)
      return resp
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const getAllPortfolio = async () => {
    try {
      // const data = await axios.get(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/listAllPortfolio`,
      // )
      const data = await callApi('/portfolio/listAllPortfolio', 'GET', null)
      await listPortfolio(data.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const editPortfolio = async (data) => {
    try {
      // const res = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/updatePortfolio`,
      //   data,
      // )
      const res = await callApi('/portfolio/updatePortfolio', 'POST', data)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  const filterPortfolio = async (filter) => {
    try {
      // const result = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/filterPortfolio`,
      //   {
      //     filter,
      //   },
      // )
      const result = await callApi('/portfolio/filterPortfolio', 'POST', { filter })

      await listPortfolio(result.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const FilterClientPortfolio = async (filter) => {
    try {
      // const result = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/filterPortfolio`,
      //   {
      //     filter,
      //   },
      // )
      const result = await callApi('/portfolio/filterClientPortfolio', 'POST', { filter })

      await listPortfolio(result.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const removePortfolio = async (data) => {
    try {
      // const resp = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/deletePortfolio`,
      //   data,
      // )
      const resp = await callApi('/portfolio/deletePortfolio', 'POST', data)
      return resp
      // dispatch(UserActions.deleteIdent(id))
    } catch (err) {
      console.log(err)
    }
  }

  const addTraderToPortfolio = async (data) => {
    try {
      // const resp = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/newTraderToPortfolio`,
      //   data,
      // )
      const resp = await callApi('/portfolio/newTraderToPortfolio', 'POST', data)
      return resp
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const addPortfolioAvecTrader = async (data) => {
    try {
      // const resp = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/newPortfolioAvecTrader`,
      //   data,
      // )
      const resp = await callApi('/portfolio/newPortfolioAvecTrader', 'POST', data)
      return resp
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const getPortfolioWithTrader = async (data) => {
    try {
      // const result = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/getPortfolioWithTrade`,
      //   {
      //     data,
      //   },
      // )
      const result = await callApi('/portfolio/getPortfolioWithTrade', 'POST', { data })
      return result
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const getTraderAvecPortfolio = async (data) => {
    try {
      // const result = await axios.post(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/getTraderAvecPortfolio`,
      //   {
      //     data,
      //   },
      // )
      const result = await callApi('/portfolio/getTraderAvecPortfolio', 'POST', { data })
      return result
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const getPortfolioTradeScope = async (data) => {
    try {
      const result = await callApi('/portfolio/listPortfolioScope', 'POST', { data })
      return result
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const getPortfolioArchived = async () => {
    try {
      // const data = await axios.get(
      //   `${process.env.REACT_APP_API_ENDPOINT}/portfolio/listAllPortfolio`,
      // )
      const data = await callApi('/portfolio/listPortfolioArchived', 'GET', null)
      return data
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const ListPortfolioWithTrader = async (data) => {
    try {
      const result = await callApi('/portfolio/listPortfolioWithTrader', 'POST', data)
      // console.log('PORTFOL ', result?.data)
      getListPortfolTrader(result?.data)
    } catch (err) {
      console.log('Error ', err)
    }
  }

  const dispatch = useDispatch()

  const dataDetailsPortfolio = useSelector(resultDetailsPortfolio)
  const dataListPortfolio = useSelector(resultListPortfolio)
  const dataPortfolioTrader = useSelector(resultPortfolioTrader)

  return {
    ...dataDetailsPortfolio,
    ...dataListPortfolio,
    ...dataPortfolioTrader,
    listPortfolio,
    setDetailsPortfolio,
    createPortfolio,
    getAllPortfolio,
    editPortfolio,
    filterPortfolio,
    removePortfolio,
    addTraderToPortfolio,
    getPortfolioWithTrader,
    getTraderAvecPortfolio,
    addPortfolioAvecTrader,
    getPortfolioArchived,
    ListPortfolioWithTrader,
    getPortfolioTradeScope,
    FilterClientPortfolio,
  }
}

export default usePortfolio
