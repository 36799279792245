import { OverviewActions } from '../store/actions'
import {
  resultPortfolioOverview,
  resultportfolioSelectedPandL,
  resultSelectDash,
  resultDataOverview,
  resultDataCriptPDF,
} from '../store/reducers'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { callApi } from 'src/services/callApi'
import { getTokenQuantTools, VerifTokenQuantTools } from '../services/ApiService/instrument'
import {
  fetchDataOverview,
  fetchBinaryPdfData,
  downloadTransactionNotice,
  genererTransactionNotice,
  genererExcel,
} from '../services/ApiService/overview'
import { useUser } from '../hooks'

const useOverview = () => {
  const { userAuth } = useUser()

  const getAllPortfolioOverview = (dataOverviewPortfolio) => {
    dispatch(OverviewActions.getAllPortfolioOverview(dataOverviewPortfolio))
  }

  const setPortfolioSelected = (dataPortfolioSelected) => {
    dispatch(OverviewActions.setPortfolioSelected(dataPortfolioSelected))
  }

  const setSelectDashboard = (dataSelectDash) => {
    dispatch(OverviewActions.setSelectDashboard(dataSelectDash))
  }

  const getDataOverviewInApi = (dataOverview) => {
    dispatch(OverviewActions.getDataOverviewInApi(dataOverview))
  }

  const getDataCriptPDF = (dataCriptPdf) => {
    dispatch(OverviewActions.getDataCriptPDF(dataCriptPdf))
  }

  const fetchToken = async () => {
    try {
      const resp = await callApi('/users/getAuthorisationKey', 'POST', { idUser: userAuth?._id })

      const formData = new FormData()
      formData.append('username', resp?.data?.idUser)
      formData.append('password', resp?.data?.apiKey)
      // formData.append('username', '64fec28c9f71ab002b9c7ae7')
      // formData.append('password', '48HD7AEtFzigL0akLYoh60ZANeYU0A')

      const tokenAuth = await getTokenQuantTools(formData)

      // Stocker le token dans la session
      sessionStorage.setItem('tokenNew', tokenAuth?.data?.access_token)
    } catch (err) {
      console.log('ERROR', err)
    }
  }

  const getDataOverview = async () => {
    const sessionToken = sessionStorage.getItem('tokenNew')
    // console.log('DEBUT VERIFICATION')

    if (sessionToken != null) {
      const verifToken = await VerifTokenQuantTools(sessionToken)

      // console.log('PREMIER')

      if (verifToken?.data?.exp && verifToken?.data?.sub === userAuth?._id) {
        // console.log('DEUXIEME')
        // await fetchDataOverview(sessionToken, getDataOverviewInApi)
        const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
        const axiosInstance = axios.create({
          baseURL: apiQuanttoolsUrl,
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        })

        const response = await axiosInstance.get('/dashboard/overview_trades/')
        getDataOverviewInApi(response?.data)
      } else {
        // console.log('TROISIEME')
        await fetchToken()
        const sessionToken = sessionStorage.getItem('tokenNew')
        // await fetchDataOverview(sessionToken, getDataOverviewInApi)
        const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
        const axiosInstance = axios.create({
          baseURL: apiQuanttoolsUrl,
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
        })

        const response = await axiosInstance.get('/dashboard/overview_trades/')
        getDataOverviewInApi(response?.data)
      }
    } else {
      // console.log('QUATRIEME')
      await fetchToken()
      const sessionToken = sessionStorage.getItem('tokenNew')
      // await fetchDataOverview(sessionToken, getDataOverviewInApi)
      const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
      const axiosInstance = axios.create({
        baseURL: apiQuanttoolsUrl,
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      })

      const response = await axiosInstance.get('/dashboard/overview_trades/')
      getDataOverviewInApi(response?.data)
    }
  }

  const generateTransactionNotice = async (dataAuthorisation, dataGenerate) => {
    try {
      const resp = await callApi('/users/getAuthorisationKey', 'POST', dataAuthorisation)

      const formData = new FormData()
      formData.append('username', resp?.data?.idUser)
      formData.append('password', resp?.data?.apiKey)
      // formData.append('username', '650afb3abc070d002b88e30d')
      // formData.append('password', 'nRLPxoxLUbGfWYqngC00hZNhsTh5eR')

      const sessionToken = sessionStorage.getItem('tokenNew')

      // const res = await callApi('/newProductTypes/getBinaryPdfData', 'POST', {
      //   tradeId: dataGenerate?.trade_id,
      // })
      const res = await fetchBinaryPdfData(dataGenerate)

      if (res?.data) {
        // const result = await axios({
        //   url: `https://staging.phalanx-capital.eu:8444/transaction_notice/download/`,
        //   method: 'POST',
        //   data: dataGenerate,
        //   // data: {
        //   //   trade_id: '64d4d607e97f54e3099370eb',
        //   // },
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: `Bearer ${sessionToken}`,
        //   },
        //   responseType: 'blob', // Spécifiez que la réponse est un blob (fichier)
        // })
        const result = await downloadTransactionNotice(dataGenerate, sessionToken)

        return result
      } else {
        // await axios({
        //   url: `https://staging.phalanx-capital.eu:8444/transaction_notice/generate/`,
        //   method: 'POST',
        //   data: dataGenerate,
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: `Bearer ${sessionToken}`,
        //   },
        // })
        await genererTransactionNotice(dataGenerate, sessionToken)

        // const result = await axios({
        //   url: `https://staging.phalanx-capital.eu:8444/transaction_notice/download/`,
        //   method: 'POST',
        //   data: dataGenerate,
        //   // data: {
        //   //   trade_id: '64d4d607e97f54e3099370eb',
        //   // },
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: `Bearer ${tokenAuth?.data?.access_token}`,
        //   },
        //   responseType: 'blob', // Spécifiez que la réponse est un blob (fichier)
        // })

        const result = await downloadTransactionNotice(dataGenerate, sessionToken)

        return result
      }
    } catch (error) {
      if (error.message.includes('401')) {
        console.log('ERROR ', error)
        window.location.replace('/liveTrade')
      }

      throw error
    }
  }
  const generateExcel = async (dataAuthorisation, dataGenerate) => {
    try {
      const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
      const resp = await callApi('/users/getAuthorisationKey', 'POST', dataAuthorisation)

      const formData = new FormData()
      formData.append('username', resp?.data?.idUser)
      formData.append('password', resp?.data?.apiKey)
      // formData.append('username', '64fec28c9f71ab002b9c7ae7')
      // formData.append('password', '48HD7AEtFzigL0akLYoh60ZANeYU0A')

      const tokenAuth = await axios.post(`${apiQuanttoolsUrl}/token`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for FormData
        },
      })

      // const sessionToken = sessionStorage.getItem('tokenNew')

      const response = await axios.post(
        `${apiQuanttoolsUrl}/dashboard/overview_trades_excel/`,
        dataGenerate,
        // {
        //   portfolios: ['650afe5012c2c6002b034078'],
        //   dashboard_report: true,
        //   trades_details: true,
        // },
        {
          headers: {
            Authorization: `Bearer ${tokenAuth?.data?.access_token}`,
            // Authorization:
            //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NTBhZmIzYWJjMDcwZDAwMmI4OGUzMGQiLCJleHAiOjE2OTU2MjM3Nzd9.CQ3h7k3qxWM9kRj4E29EOBK1mZZGvFg2ipsybKlkXnM',
            'Content-Type': 'application/json',
          },
          responseType: 'arraybuffer', // Important for binary data like files
        },
      )
      // const response = await genererExcel(dataGenerate, sessionToken)

      return response
    } catch (error) {
      if (error.message.includes('401')) {
        console.log('ERROR ', error)
      }

      throw error
    }
  }

  //   const getAll = async () => {
  //     try {
  //       const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/overview/listAll`)
  //     } catch (err) {
  //       console.log('Error ', err)
  //     }
  //   }

  const dispatch = useDispatch()

  const dataOverviewPortfolio = useSelector(resultPortfolioOverview)
  const dataPortfolioSelected = useSelector(resultportfolioSelectedPandL)
  const dataSelectDash = useSelector(resultSelectDash)
  const dataOverview = useSelector(resultDataOverview)
  const dataCriptPdf = useSelector(resultDataCriptPDF)

  return {
    ...dataOverviewPortfolio,
    ...dataPortfolioSelected,
    ...dataSelectDash,
    ...dataOverview,
    ...dataCriptPdf,
    // getAll,
    getAllPortfolioOverview,
    setPortfolioSelected,
    setSelectDashboard,
    getDataOverview,
    generateTransactionNotice,
    generateExcel,
    fetchToken,
  }
}

export default useOverview
