import axios from 'axios'
import * as _ from 'lodash'
import { callApi } from '../callApi'

export const fetchAuthorisationKey = async (keyAuth) => {
  const resp = await callApi('/users/getAuthorisationKey', 'POST', keyAuth)
  return resp
}

export const listInstrument = async (Instru, setDataMaturity, setListCall) => {
  const InstrumentName = []
  await axios
    .get(
      `https://www.deribit.com/api/v2/public/get_instruments?currency=${Instru}&expired=false&kind=option`,
    )
    .then((res) => {
      const length = res.data.result.length
      for (let i = 0; i < length; i++) {
        InstrumentName.push(res.data.result[i])
      }

      setDataMaturity(InstrumentName)

      const instrumentNames = InstrumentName.map((item) => item.instrument_name)
      const datesMaturite = instrumentNames.map((name) => name.split('-')[1])

      const MaturityFiltred = _.sortedUniq(datesMaturite)

      setListCall(MaturityFiltred)
    })
}

export const orderBooks = async (name) => {
  const request = axios.get(
    `https://www.deribit.com/api/v2/public/get_order_book?instrument_name=${name}`,
  )
  return request
}

export const fetchStrikePut = async (name) => {
  const request = axios.get(
    `https://www.deribit.com/api/v2/public/get_order_book?instrument_name=${name}`,
  )
  return request
}

export const fetchPriceStructuring = async (setStructuringPrice, NotificationFastPrice) => {
  try {
    const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
    const sessionToken = sessionStorage.getItem('tokenNew')
    const response = await axios.request({
      // timeout: 8000,
      method: 'GET',
      url: `${apiQuanttoolsUrl}/pricing/structuredproduct/underlying_prices/`,
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })

    setStructuringPrice(response?.data)
    NotificationFastPrice({ message: '', notif: false })
  } catch (error) {
    NotificationFastPrice({ message: error?.message, notif: true })
  }
}

export const fetchDataQuantTools = async (userAuth, fetchToken) => {
  // CODEE VERIF TOKEN
  const sessionToken = sessionStorage.getItem('tokenNew')
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS

  if (sessionToken != null) {
    const verifToken = await axios.get(`${apiQuanttoolsUrl}/token/check/`, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })

    if (verifToken?.data?.exp && verifToken?.data?.sub === userAuth?._id) {
      const datas = await axios.request({
        url: `${apiQuanttoolsUrl}/pricing/structuredproduct/products/?with_constraints=true`,
        // timeout: 8000,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })

      return datas
    } else {
      await fetchToken()
      const sessionTokenM = sessionStorage.getItem('tokenNew')
      const datas = await axios.request({
        url: `${apiQuanttoolsUrl}/pricing/structuredproduct/products/?with_constraints=true`,
        // timeout: 8000,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionTokenM}`,
        },
      })

      return datas
    }
  } else {
    await fetchToken()
    const sessionTokenN = sessionStorage.getItem('tokenNew')
    const datas = await axios.request({
      url: `${apiQuanttoolsUrl}/pricing/structuredproduct/products/?with_constraints=true`,
      // timeout: 8000,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionTokenN}`,
      },
    })

    return datas
  }
}

export const fastPriceQuantTools = async (data) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const sessionToken = sessionStorage.getItem('tokenNew')
  const result = await axios({
    url: `${apiQuanttoolsUrl}/pricing/structuredproduct/fast-prices/`,
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
    },
  })

  return result
}

export const preExecCall = async (data, keyAuth) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const result = await axios({
    url: `${apiQuanttoolsUrl}/pricing/structuredproduct/pre-execution/`,
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keyAuth}`,
    },
  })
  return result
}

export const VerifTokenQuantTools = async (token) => {
  try {
    const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
    const tokenVerif = await axios.get(`${apiQuanttoolsUrl}/token/check/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return tokenVerif
  } catch (error) {
    return error
    // console.log('ERREUR /CHECH', error)
  }
}

export const getTokenQuantTools = async (formData) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const tokenAuth = await axios.post(`${apiQuanttoolsUrl}/token`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important for FormData
    },
  })
  return tokenAuth
}

export const preExecEarlyTerm = async (data, tokenAuth) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const result = await axios({
    url: `${apiQuanttoolsUrl}/pricing/structuredproduct/early-termination-preexecution/`,
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenAuth?.data?.access_token}`,
    },
  })
  return result
}

export const execTermination = async (data, tokenAuth) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const result = await axios({
    url: `${apiQuanttoolsUrl}/pricing/structuredproduct/early-termination-execution/`,
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenAuth?.data?.access_token}`,
    },
  })
  return result
}

export const productExecution = async (data, keyAuth) => {
  const apiQuanttoolsUrl = process.env.REACT_APP_QUANTTOOLS
  const result = await axios({
    url: `${apiQuanttoolsUrl}/pricing/structuredproduct/execution/`,
    method: 'POST',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${keyAuth}`,
    },
  })
  return result
}
