import { callApi } from '../callApi'
import axios from 'axios'

export const newClient = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/client/newClient`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const listAllClient = async () => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/client/listAllClients`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const modifClient = async (data) => {
  try {
    await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/client/updateClient`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const filtreClient = async (filter) => {
  const result = await callApi('/client/filterClient', 'POST', { filter })
  return result
}

export const deleteClient = async (data) => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/client/deleteClient`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}

export const fetchClientArchived = async () => {
  try {
    return await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/client/listClientsArchived`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
  } catch (error) {
    if (error.message.includes('401')) {
      window.location.replace('/')
    }

    throw error
  }
}
