const INITIAL_STATE = {
  portfolioOverview: {},
  portfolioSelectedPandL: [],
  selectDash: 'nbContract',
  dataOverview: [],
  dataCriptPdf: '',
}

const apiOverview = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ALLPORTFOLIOOVERVIEW': {
      return { ...state, portfolioOverview: action.payload }
    }
    case 'PORTFOLIOSELECTED': {
      return { ...state, portfolioSelectedPandL: action.payload }
    }
    case 'SELECTDASHBOARD': {
      return { ...state, selectDash: action.payload }
    }
    case 'DATAOVERVIEW': {
      return { ...state, dataOverview: action.payload }
    }
    case 'DATACRIPTPDF': {
      return { ...state, dataCriptPdf: action.payload }
    }
    default:
      return state
  }
}

export default apiOverview
