export const getAllPortfolioOverview = (data) => (dispatch) => {
  dispatch({
    type: 'ALLPORTFOLIOOVERVIEW',
    payload: data,
  })
}

export const setPortfolioSelected = (data) => (dispatch) => {
  dispatch({
    type: 'PORTFOLIOSELECTED',
    payload: data,
  })
}

export const setSelectDashboard = (data) => (dispatch) => {
  dispatch({
    type: 'SELECTDASHBOARD',
    payload: data,
  })
}

export const getDataOverviewInApi = (data) => (dispatch) => {
  dispatch({
    type: 'DATAOVERVIEW',
    payload: data,
  })
}

export const getDataCriptPDF = (data) => (dispatch) => {
  dispatch({
    type: 'DATACRIPTPDF',
    payload: data,
  })
}
