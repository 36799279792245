const INITIAL_STATE = {
  listUsers: [],
  allIdentity: [],
  userAuth: {},
  token: null,
  dataEditUser: {},
  gettingUser: false,
  oneUserInfo: {},
  identityTrader: [],
  keyAuthorisation: '',
  // tokenTemporary: '',
  // openEmailDialog: false,
  // openDeleteDialog: false,
}

const apiUser = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EDITUSER': {
      const listUser = state.allIdentity
      const userUpdated = listUser.map((x) => {
        if (x._id === action.payload._id) {
          return action.payload
        }
        return x
      })
      return { ...state, allIdentity: userUpdated }
    }
    case 'ALLIDENTITY': {
      return { ...state, allIdentity: action.payload }
    }
    case 'TOKEN': {
      return { ...state, token: action.payload }
    }
    case 'ALLUSERS': {
      return { ...state, listUsers: action.payload }
    }
    case 'USERAUTH': {
      return { ...state, userAuth: action.payload }
    }
    case 'DELETEIDENTITY': {
      const previousState = { ...state }
      return {
        ...state,
        allIdentity: previousState.allIdentity.filter(
          (allIdent) => allIdent._id !== action.payload,
        ),
      }
    }
    case 'ADDIDENTITY': {
      const previousState = { ...state }
      return {
        ...state,
        allIdentity: [...previousState.allIdentity, action.payload],
      }
    }
    case 'GETUSERS': {
      return { ...state, gettingUser: action.payload }
    }
    case 'GETONEUSER': {
      return { ...state, oneUserInfo: action.payload }
    }
    case 'GETTRADERS': {
      return { ...state, identityTrader: action.payload }
    }
    case 'KEYAUTHORISATION': {
      return { ...state, keyAuthorisation: action.payload }
    }
    // case 'OPENDETAILUSERDIALOG': {
    //   return { ...state, openDetailUserDialog: action.payload }
    // }
    // case 'OPENEMAILDIALOG': {
    //   return { ...state, openEmailDialog: action.payload }
    // }
    // case 'TOKENTEMPORAIRE': {
    //   return { ...state, tokenTemporary: action.payload }
    // }
    default:
      return state
  }
}

export default apiUser
