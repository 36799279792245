import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { CSpinner } from '@coreui/react'
import './scss/style.scss'
import ProtectedRoute from './services/ProtectedRoute'
import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react'
import { useInstrument, useOverview } from './hooks'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const NewPaswd = React.lazy(() => import('./views/pages/newPwd/NewPwd'))
const ForgotPasswd = React.lazy(() => import('./views/pages/login/ForgotPwd'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
// Overview
const Overview = React.lazy(() => import('./views/overview/overview/Overview'))
// Trade
const Trade = React.lazy(() => import('./views/trade/Trade'))
// Trade

const TradeQuantTools = React.lazy(() => import('./views/tradeQuantTools/Trade'))

// Wallet
const Wallet = React.lazy(() => import('./views/wallet/wallet/Wallet'))

// LiveTrade
const LiveTrade = React.lazy(() => import('./views/liveTrade/liveTrade/LiveTrade'))

// TradeHistory
const TradeHistory = React.lazy(() => import('./views/tradeHistory/tradeHistory/TradeHistory'))

// Setting
const Setting = React.lazy(() => import('./views/setting/setting/Setting'))

// Register
const Register = React.lazy(() => import('./views/pages/register/Register'))

// ListRegister
const ListRegister = React.lazy(() => import('./views/pages/register/ListRegister'))

// Client
const Client = React.lazy(() => import('./views/admin/client/client'))

// Add User Identity
const UserIdentity = React.lazy(() => import('./views/admin/users/userIdentity'))

// List Users
const ListUsers = React.lazy(() => import('./views/admin/users/listUsers'))

// Portfolio
const Portfolio = React.lazy(() => import('./views/admin/portfolios/portfolio'))

const App = () => {
  const token = localStorage.getItem('ACCESS_TOKEN')

  const { notifFastPrice } = useInstrument()
  const { fetchToken } = useOverview()

  const getTokenQuant = async () => {
    await fetchToken()
  }

  useEffect(() => {
    getTokenQuant()
    console.log('LOGGG 1')
  }, [])

  if (token) {
    console.log('LOGGG 2')
    getTokenQuant()
  }

  return (
    <>
      <CToast
        animation={false}
        autohide={false}
        visible={notifFastPrice?.notif}
        style={{
          position: 'fixed', // Utilisation de 'fixed' pour que la toast reste en place
          top: '70px',
          right: '20px',
          zIndex: '1000', // Ajout de z-index élevé
        }}
      >
        <CToastHeader closeButton>
          <svg
            className="rounded me-2"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
            role="img"
          >
            <rect width="100%" height="100%" fill="red"></rect>
          </svg>
          <div className="fw-bold me-auto text-danger">{notifFastPrice?.message}</div>
        </CToastHeader>
      </CToast>

      <BrowserRouter>
        {/* <Suspense fallback={loading}> */}
        <Routes>
          <Route path="/" name="Login" element={token ? <Navigate to="/overview" /> : <Login />} />
          <Route path="/password-identity/:identityId" name="New Password" element={<NewPaswd />} />
          <Route
            path="/$2a$08$bIPZotRNTVYH2HwUfLa5NeznnGzqmi06TDixGmQac7mBjr8p7UIeC/:identityId"
            name="forgot Password"
            element={<ForgotPasswd />}
          />
          <Route path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />

          <Route
            path="/overview"
            name="Overview"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <Overview />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          {/* <Route
            path="/trade"
            name="Trade"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <Trade />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          /> */}

          <Route
            path="/TradeQuantTools"
            name="TradeQuantTools"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <TradeQuantTools />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          {/* <Route
            path="/wallet"
            name="Wallet"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <Wallet />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          /> */}

          <Route
            path="/liveTrade"
            name="LiveTrade"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <LiveTrade />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          <Route
            path="/tradeHistory"
            name="TradeHistory"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <TradeHistory />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          {/* <Route
            path="/setting"
            name="Setting"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <Setting />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          /> */}

          <Route
            path="/listRegister"
            name="ListRegister"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <ListRegister />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          <Route
            path="/AddUserIdentity"
            name="AddUserIdentity"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <UserIdentity />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          <Route
            path="/listUsers"
            name="ListUsers"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <ListUsers />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          <Route
            path="/portfolio"
            name="Portfolio"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <Portfolio />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          <Route
            path="/client"
            name="Client"
            element={
              <DefaultLayout>
                <Suspense fallback={<CSpinner color="primary" />}>
                  <ProtectedRoute token={token}>
                    <Client />
                  </ProtectedRoute>
                </Suspense>
              </DefaultLayout>
            }
          />

          <Route path="/*" name="Page 404" element={<Page404 />} />
        </Routes>
        {/* </Suspense> */}
      </BrowserRouter>
    </>
  )
}

export default App
