import { Action, applyMiddleware, createStore, StoreEnhancer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import rootReducer from './reducers'

// ---- middleware ----//
const middlewares = [thunk]
const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)
// rehydrate state on app start
const initialState = {}
// ---- store ----//
const store = createStore(rootReducer, initialState, composedEnhancers)
// export type RootState = ReturnType<typeof rootReducer>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;

// export type Dispatch<S> = ThunkDispatch<S, null, Action<string>>;
// export type AppDispatch = Dispatch<RootState>;

export default store

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
}
